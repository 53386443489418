@import url(https://use.typekit.net/zgy3rom.css);
/*font menlo*/

html,
body,
#root,
.App {
  height: 100vh;
  scroll-behavior: smooth;
}

body {
  /*font-family: "Menlo", sans-serif;*/
  font-style: normal;
}

/* header */
.header{
  display: flex;
  flex-direction: row;
}

.logo {
  /*font-family: "Poppins", sans-serif;*/
  font-size: 24px;
  font-weight: bold;
  margin: 5px;
  display: block;
  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
}

a:link{
  color:black;
  text-decoration: none;
  font-weight: bold;

}

a:visited{
  color: black;
  font-weight: bold;
}

a:hover{
  /*color:rgba(0, 178, 255, 1);*/
  text-decoration: none;
}

.slogan {
  font-size: 20px;
  font-weight: normal;
}

.content {
  position: static;
  display: flex;
  flex-flow: row;
  background-color: rgb(255, 255, 255);
  height: 100%;
  width: 100%;
}

/*chart*/
.chart {
  position: relative;
  min-height: 200px;
  flex: 60% 1;
  display: flex;
  flex-flow: column;
  padding-left: 0px;
  height: 100vh;
  background: rgb(255, 255, 255);
  margin-bottom: 10px;
}

.timescaleContainer {
  position: absolute;
  top: 0;
  left: 50px;
  display: flex;
  background: transparent;
  font-size: 20px;
}

.timescale {
  padding: 30px;
  width: 5vh;
  color: grey;
}
.timescale:hover {
  color: rgb(0, 0, 0);
  font-weight: bold;
  opacity: 1;
}

.graph {
  position: relative;
  background-color: rgb(255, 255, 255);
  height: 93%;
}
/*portfoliopanel*/
.portfolioPanel {
  flex: 40% 1;
  background: rgb(134, 86, 28);
}

.case {
  display: flex;
}

.topBar {
  padding-left: 10px;
  margin-bottom: 25px;
  display: flex;
}

.currency {
  padding-right: 25px;
  margin-bottom: 5px;
  margin-left: auto;
}

.portfolioselector {
  font-weight: bold;
  /*font-family: "Poppins", sans-serif;*/
}

.property {
  font-size: 16px;
  background: rgb(255, 255, 255);
  margin-top: 0px;
}

.propertytop {
  font-size: 16px;
  background: rgb(255, 255, 255);
  text-align: center;
  margin-right: 10px;
  margin-bottom: 0px;
}

#qtytop {
  text-align: left;
  width: 50px;
}

#nametop {
  width: 240px;
}

#pricetop {
  width: 50px;
}

#valuetop {
  width: 70px;
}

#movetop {
  width: 50px;
  margin-right: 20px;
}

.separator {
  margin-right:20px;
  margin-top: 0px;
  margin-bottom: 0px;
}

#qty {
  width: 50px;
  text-align: right;
  margin-right: 10px;
}

#name {
  width: 240px;
  margin-right: 10px;
}

#prices {
  width: 50px;
  margin-right: 20px;
}

#value {
  width: 50px;
  margin-right: 20px;
  text-align: right;
}

#move {
  width: 50px;
  margin-right: 10px;
  text-align: right;
}

.delete {
  width: 20px;
  margin-right: 20px;
}

.delete:hover {
  -webkit-filter: brightness(0) saturate(100%);
          filter: brightness(0) saturate(100%);
}

#dropdown-basic-button {
  color: black;
  border-color: white;
  background: white;
}

.addcase {
  display: flex;
  margin-left: 60px;
}

.caseqty {
  font-size: 16px;
  background: rgb(255, 255, 255);
  margin-top: 20px;
  width: 50px;
  text-align: right;
  margin-right: 10px;
  margin-bottom: 0px;
}

#casename {
  margin-right: 20px;
  background: rgb(255, 255, 255);
}

.addcasebutton {
  margin-top: 25px;
  margin-right: 30px;
  width: 70px;
  font-size: 32px;
  text-align: center;
  color: grey;
}
.addcasebutton:hover {
  color: rgb(0, 0, 0);
}

.inventory {
  height: 250px;
  max-height: 300px;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.alert {
  width: 300px;
  margin: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
  border-top: 0px;
}

.placeholder {
  color:rgb(255, 255, 255);
  height: 98.5px;
  width: 100px; 
}

.emailform {
  padding-left: 10px;
  margin-top: 0px;
  padding-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 24px;
}

.emailTitle{
  margin-top: 0px;
}

.email {
  justify-content: center;
  width: 200px;
}

.emailField {
  display: flex;
  flex-direction: row;
}

#submit {
  /*font-family: "Poppins", sans-serif;*/
  background-color: rgba(0, 178, 255, 1);
  border-color: rgba(0, 178, 255, 1);
  margin-top: 20px;
  margin-left: 10px;
  margin-bottom: 30px;
}

#submit:hover{
  background-color: rgba(0, 178, 255, 0.7);
  border-color: rgba(0, 178, 255, 0.7);
}

.emailAlert {
  margin-bottom: 11%;
}

/*footer*/

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(255, 255, 255);
  width: 100%;
}

.footercontainer {
  display: flex;
  flex-direction: row-reverse;
}

.aboutLink {
  margin-left: 50px;
  margin-right: 10px;
}

.headline {
  padding-top: 50px;
  padding-bottom: 25px;
  text-align: center;
}

.about{
  margin-left: 30%;
  margin-right: 30%;
  padding-bottom: 20px
}

.creator {
  margin-left: 30%;
  margin-right: 30%;
  padding-bottom: 20px;
}

.greetings {
  text-align: center;
}

@media (max-width: 1200px) {
  .content {
    flex-flow: column;
  }
  .chart{
    height: 50vh;
  }
  .separator{
    width: 540px;
    margin: auto;
  }
  #qty {
    margin-left: 30px;
  }
  .caseqty{
    margin-left: 30px;
  }
  .case {
    justify-content: center;
  }

  .addcase {
    justify-content: center;
  }
  .emailform {
    margin-top: 10px;
  }
  .emailTitle{
    margin-top:0px;
  }

  .creator {
    margin-left: 10%;
    margin-right: 10%;
  }
  .about{
    margin-left: 10%;
    margin-right:10%;
  }
  .inventory {
    height: 150px;
  }
}


